.loader {
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    align-content: center; 
    justify-content: flex-start;  
      z-index: 100000;
  }
  
  .loader__element {
      height: 3px;
      width: 100%;  
  }
  
  .loader__element:before {
      content: '';
      display: block;
      background-color:  #FFCD12;
      height: 3px;
      width: 0;
    animation: getWidth 3s ease-in infinite;
  }
  
  @keyframes getWidth {
      100% { width: 100%; }
  }