
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$b2pay-logistics-primary: mat.define-palette(mat.$indigo-palette);
$b2pay-logistics-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$b2pay-logistics-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$b2pay-logistics-theme: mat.define-light-theme((
  color: (
    primary: $b2pay-logistics-primary,
    accent: $b2pay-logistics-accent,
    warn: $b2pay-logistics-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($b2pay-logistics-theme);

/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '../node_modules/alertifyjs/build/css/alertify.min.css';
@import '../node_modules/alertifyjs/build/css/themes/bootstrap.min.css';

// @import '~ngx-print-element/styles.css';

@import "global";
ol,
ul {
    padding-left: unset;
}

.h-10 {
  height: 2.6rem;
}

.mat-mdc-input-element {
  box-sizing: inherit !important;
}


.progress-inner{
  color: black !important;
  max-width: 100% !important;
}

.invoice-warrantly tr{
  border: 0px !important;
}

 .custom-tooltip {
  font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
}

.qrcodeshadow {
  display: flex;
  justify-content: center;
}
.qrcodeshadow img{
  width: 100%;
}
/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';



#print-invoice {
  display: none;
}





@media print {

  body{
    margin: 0;
  }
  #print-invoice {
    margin: 0px auto;
    display: block;
    width: 120mm;
    direction: rtl;
  }
  .qrcode{
    margin: 0 auto;
  }
  .main-section{
    height: auto !important;
    margin: 0px !important;
    width: 100% !important;
    padding: 0px !important;
  }
  .qrcodeshadow{width:200px; height:200px;
  margin: 0 auto;}

  .component-loader , .sidebar , app-header {
    display: none;
    height: 0px !important;
    width: 0px !important;
  }
}




#demo{
  .qrcode-container{height:100vh; width: 75mm; display: flex; flex-direction: column;}
  .barcode svg{margin: 0px auto; width: 75mm;height: 79px;}
  .qrcode{width: 29mm;margin: 0px auto;}
  .barcode{text-align:center; margin-top:0px;width: 50.8mm;}
}

