$primary-bg: #fff;
$secondary-bg: #f8f8f8;
$background:#E5E5E5;
$color: #303030;
$span_color:#969896;
$shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
$border-color: rgba(58, 58, 58, 0.15);
$space-grey: #343A40;
$dark-space-grey: #1B1B1B;
$hover-space-grey: #053e64;
$active-space-grey: #095e97;
$space-space-grey: #024F84;
$danger:#CA3737;
$text: #000;
$fontSize: 16px;
$line-height: 24px;
$lite-ccc:#ccc;
$content-form:#ced4da;
$space-gray:#dddddd8c;
$space-gray2:#a1a1a1;
$pop-color:#565656;
$header-form:#dee2e6;
// .
// .
// colors
$error: #e50000;
$success: #08629e;
$warning: #FEB019;
$select: #574edc;
