@import "../colors";
.dropdown-toggle::after {
    display: none;
}
.dropup .dropdown-toggle::after{
    display: none;
}

.menue-open {
    margin-top: 10px;
}

.navbar {
    position: fixed;
    left: 0;
    right: 0;
    background: $primary-bg;
    height: 60px;
    z-index: 15;
    padding: 1px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: $shadow;
}

@mixin biDirectional($start, $end) {
    .dropdown-menu {
        text-align: #{$start};
        #{$end}: 0;
        #{$start}: auto;
        box-shadow: $shadow;
    }

    .bell{
        border-#{$start}: 1px solid rgba(209, 213, 219, 1);
        padding-#{$start}: 8px;
    }
}

@import '../direction';