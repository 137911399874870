@import "colors";
@import "scroll_bar";
@import "form";
@import "./components/navbar";
@import "./components/sidebar";
@import "./components/loader";
@import "./components/home";
@import "./components/table";
@import "./components/details";
@font-face {
    font-family: DIN ;
    src: url('../fonts/alfont_com_AlFont_com_DINNextLTArabic-Medium-3.ttf');
}

body {
    font-family: DIN !important;
    background: $background;
    color: $color;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

span {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.63px;
    color: $span_color;
}

p {
    font-size: 18px;
    font-weight: 500;
    line-height: 26.46px;
}

h2 {
    font-size: 31px;
    font-weight: 500;
    line-height: 45.57px;
}
.mat-h2, .mat-title, .mat-typography h2 {
    font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px;
}
h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.63px;
}

.p-4 {
    padding: 1.3rem !important;
}
.pl-4 {
    padding-left: 1.3rem;
}
.pr-4 {
    padding-right: 1.3rem;
}
.pt-4{
    padding-top: 1.3rem;
}

.pointer {
    cursor: pointer;
}

.card {
    padding: 16px;
    border-radius: 10px;
    border: 0px;
   box-shadow: $shadow ;
}
.card-non-shadow {
 box-shadow: none !important;
}

.modal-footer {
    justify-content: inherit;
}




// share

share-button {
    margin: 10px;
}

.sb-material-dark .sb-wrapper {
    background-color: #2E4057;
    background-color: var(--button-color) !important;
    color: #fff !important;
    border-radius: 2px;
    margin-bottom: 18px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.sb-material-dark .sb-wrapper {
    font-size: inherit;
    cursor: pointer;
    position: relative;
    outline: 0;
    min-width: 4.125em;
    height: 2.5em;
    border: none;
    border-radius: 1px;
    padding: 0;
    line-height: 2.571em;
    background-color: rgba(0, 0, 0, 0);
    -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
}

.sb-material-dark .ng-fa-icon {
    color: #fff !important;
}

.border {
    height: auto !important;
}


.has-error{

   .form-control:focus ,.form-control:active ,.form-control ,.select {
        border:1px solid red  !important;
        box-shadow: 0px 0px 4px rgb(234 0 0 / 25%) !important;
        border-radius: 4px;
    }
}

.checkRefund{
    color: brown;
    font-weight: bold;
}

.has-error-check{
    border-color: red;
}

.bold_red_label{
    color: brown;
    font-size: 20px;
    margin: 5px;
}

.share-io{
    font-size: 25px;
    color: forestgreen;
    transition: all 0.3s ease;
}

.share-io:hover{
    font-size: 50px;
}

.swiper-pagination-s1{
    text-align: center;
    margin-bottom: 5px;
}

.otp-input{
    margin: 0 10px;
}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

.center{
    justify-content: center;
}

.absolute-center {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;
}

.holidays {
    background-color: $space-grey !important;
}

.holidays-number{
    position: absolute;
    top: 8px;
    right: 8px;
    color: #323a40;
    font-size: 17px;
    width: 30px;
    height: 30px;
    background-color: #ffc107;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.workshop-name{
    background-color: $space-grey;
    color: #fff;
}


.barcode svg {
    margin: 1px auto;
}
.show-phone{
    direction: ltr;
    text-align: -webkit-match-parent;

}

.tag-background{
    background-color: rgba(255, 205, 18, 0.44);
    text-align: center;
    color: #303030;
    border-radius: 0.5rem; /* 8px */
    margin: 0.25rem; /* 4px */
    padding: 0.25rem; /* 4px */
}

// chart
.mat-mdc-progress-bar {
  height: 7px !important;
  border-radius: 5px !important;
}

.span-style1{
  color: #FFB200;
}
.span-style2{
  color: #55CF61;
}
.span-style3{
  color: #02A0FC;
}

.p-red{
  color: #FF4560;
}
.p-red2{
  color: #FFC0C9;
}
.p-blue{
  color: #02A0FC;
}
.p-blue2{
  color: #CCF8FE;
}
.p-green{
  color: #55CF61;
}
.p-green2{
  color: #CDE9C9;
}

// Used in summary
.text-orange-500{
    color: $warning;
}
.text-red-500{
    color: $danger;
}

.text-emerald-600{
    color: rgb(5 150 105);
}

.cursor-grab{
    cursor: grab;
}

.bullet-yellow {
    color: rgba(255, 206, 86, 1);
}

.mdc-linear-progress__bar-inner{
    border: 4px solid;
}


.style1 .mdc-linear-progress__bar-inner {
    border-color: #FFB200 !important;
  }
  .style1 .mdc-linear-progress__buffer   {
    border-color: #FFF5CC !important;
  }

  .style2 .mdc-linear-progress__bar-inner {
    border-color: #55CF61 !important;
  }
  .style2 .mdc-linear-progress__buffer  {
    border-color: #c6f0ca !important;
  }
  .style3 .mdc-linear-progress__bar-inner {
    border-color: #02A0FC !important;
  }
  .style3 .mdc-linear-progress__buffer  {
    border-color: #CCF8FE !important;
  }
