@import "colors";
.table-container{
background: $primary-bg;
border-radius: 10px;
}
.nav-link{
    color: black !important;
}
.nav-link.active {
    color: $space-grey !important;
    border-bottom: 4px solid !important;
}
.spam{
  padding: 5px;
}

@mixin biDirectional($start, $end) {
  .spam-multy:not(:last-child) {
    border-#{$end}:1px solid $space-gray2;
  }
}


.spam-border-top {
  border-top:1px solid $space-gray2;
}
.green-text{
  color: $space-grey;
}
.filter {
    height: 0px;
    overflow: hidden;
    padding: 0px ;
    border: 0px !important;
    transition: all 0.5s ease;

    .input-group {
        width: 200px;
    }
    .form-select {
        display: block;
        width: 210px;
    }
    .form-check .form-check-input {
        float: right;
        margin-left: 0.5em !important;
        margin-top: 6px;
    }
}
.page-link{
    font-size: 12px;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 33px;
    padding: 0px;
    background-color: transparent !important;
    color: black !important;
}
.page-item:first-child .page-link ,.page-item:last-child .page-link{
    border: 0;
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 15px;
    width: auto;
    height: auto;
}
.page-item:first-child .page-link:hover ,.page-item:last-child .page-link:hover{
    border: 0;
    color: $span_color;
}
.page-item .page-link {
    margin-left: 4px !important;
}

.page-item .active{
    position: relative;
    display: block;
    color: white !important;
    text-decoration: none;
    background-color: $active-space-grey !important;
    border: 0px solid $header-form;
    border-radius: 50%;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.page-link {
    color: $color;
    border: 0px solid $header-form;
    border-radius: 50%;
}


.page-link:hover {
    z-index: 2;
    color: $color;
    background-color: $background;
    border-color: $background;
    border-radius: 50%;
}

.pagination-list{
    border-top: 1px solid rgba(209, 213, 219, 1)!important;
    padding: 0.5rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}
.btn-open-filter{
  margin: 2px;
  color: white !important;
  background-color: $active-space-grey;
  border-color: $active-space-grey;
  box-shadow: 0 10px 20px -10px $active-space-grey;
}
.filter.open-filter {
    overflow: initial;
    height: auto;
    padding: 1.3rem !important;
    border-top: 1px solid rgba(209, 213, 219, 1)!important;

}
.select-ng-tabel{
  width: 100% !important;
}

td .select-ng-tabel{
    min-width: 130px !important;
}
.select{
    width: 20vh;
}
.table{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}
.table thead tr{
    background: $background;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

}
.table>:not(:last-child)>:last-child>* {
    border-bottom: 0px;
}

.table tr{
    border-top: 1px solid rgba(209, 213, 219, 1)!important;
}

.table th ,.table td{
    padding: 1.3rem;
}

.table td .edit {
    font-size: 25px;
    color: $active-space-grey;
}
.edit {
    font-size: 25px;
    color: $active-space-grey;
}
.table td{
    font-size: 15px;
}

.table .form-check {
    margin: 0px;
    padding: 0px;
}
.table>:not(caption)>*>* {
    border-bottom-width: 0px;
}
.delete{
    font-size: 25px;
    color: $danger !important;
}
.sumary{
    color: $space-grey !important;
}
@media (max-width:1000px) {
    .table {
        display: block;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
    }
}

.modal-content .table {
    display: grid;
    width: 100%;
    overflow: auto;
    // overflow-x: auto;
}

.table>:not(caption)>*>* {
    background-color: transparent;
}


@import '../direction';
