
@import "colors";

.btn {
    background-color: $space-gray;
}
.btn-tr{
  background-color: transparent;
}

.form-control {
    display: block;
    width: 100%;
    min-width: 100px;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: $color;
    background-color: $primary-bg !important;
    background-clip: padding-box;
    border: 1px solid $content-form !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: $shadow;
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

// table .form-control {
//     width: auto;
// }
.date{
    padding: 0.375rem 2.5rem !important
    
}

.form-control:focus {
    border: 0px !important;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(18, 89, 255, 0.5);
  }

.btn-danger {
    color: #fff;
    background-color: $danger;
    border-color: $danger;
}
.btn-primary {
    margin: 2px;
    color: $primary-bg;
    background-color: $active-space-grey;
    border-color: $active-space-grey;
    box-shadow: 0 10px 20px -10px $active-space-grey;
}

.btn-outline-warning {
    color: $active-space-grey;
    border-color: $active-space-grey;
}

.btn-outline-warning:hover ,.btn-outline-warning:active , .btn-outline-warning:focus, .btn-outline-warning:disabled {
    background-color: $hover-space-grey;
    border-color: $hover-space-grey;
    color: $primary-bg;
}

.btn-primary-t {
  color: $space-grey;
  background-color: transparent;
  border-color: $space-grey;
}


.btn-primary-t2 {
  margin: 2px;
  color: #fff;
  background-color: transparent;
  border-color: $active-space-grey;
}
  .ng-select.custom  .ng-select-container{
    background-color: #ffeead  !important;
    color: #fff !important;
  }




//   .ng-select  .ng-value-container{
//     display: flex;
//     justify-content: flex-end;
//   }

.btn-default {
    box-shadow: 0 10px 20px -10px $space-gray2;
}
.image{
  width: 7rem;
  height: 7rem;
  box-shadow: 0 10px 20px -10px $pop-color;
}
.image-small{
    width: 3rem;
    height: 3rem;
    box-shadow: 0 10px 20px -10px $pop-color;
  }

.btn-primary:hover ,.btn-primary:active , .btn-primary:focus, .btn-primary:disabled {
    background-color: $hover-space-grey;
    border-color: $hover-space-grey;
}
.trash{
    color: $danger;
    font-size: 30px;
}
.weight-label{
    font-weight: bold;
}
.btn-primary:focus ,.form-control:focus .btn-default:focus ,.btn-default:active {
    box-shadow: 0 0 0 0.25rem rgba(18, 89, 255, 0.5) !important;
}
.form-select:focus ,.form-check-input:focus{
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(18, 89, 255, 0.5);
  }

  .form-check-input:checked {
    background-color: $active-space-grey;
    border-color: $active-space-grey;
}

.form-check-input:hover ,.form-check-input:active , .form-check-input:focus{
    color:$space-space-grey
}


.form-switch .form-check-input:checked {
    background-size: auto !important;
}




.modal-header {
    border-bottom: 0px solid $header-form;
    padding: 2rem;
    font-weight: 500;
    font-size: 1.85rem;
}

.form-group label{
    margin-bottom: 4px;
}

.form-group ,.form-check{
    margin-bottom: 5px;
    padding: 0;
}

.form-floating>label {
    left: auto;
}

.file{
    .form-control {
        padding: .375rem .75rem !important;
    }
}

.ng-select .ng-select-container {
    color: $color;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $lite-ccc;
    min-height: 42px;
}

.ng-select{
    box-shadow: $shadow;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
    box-shadow: 0 0 0 0.1rem rgba(18, 89, 255, 0.5);
    border-color: transparent;
}

.ng-select span {
    color: $color;
}

.mat-dialog-container {
    height: auto !important;
    overflow-y: scroll !important;
}
.mat-mdc-dialog-container  {
    height: auto !important;
    overflow-y: scroll !important;
}

.cdk-overlay-pane {
    border-radius: 6px;
    overflow: hidden;
}

.modal-content .form-check{
    box-shadow: $shadow;
    border: 1px solid $content-form !important;
    border-radius: .25rem;
    height: calc(100% - 15px);
}
.form-floating>label {
    top: -8px;
}

.modal-wrapper{
    padding: 10px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 14px;
    margin: 10px 0px;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    box-shadow: $shadow;
}

.ng-option.ng-option-disabled span{
    font-weight: bold !important;
}

textarea.form-control {
    height: 42px;
}

.auth{
    direction: rtl !important;
}

@mixin biDirectional($start, $end) {
    .auth ,.login{
        text-align: $start !important;
    }

    .iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
        left: 0 !important;
        right: auto !important;
    }

    .iti__country-list {
        left: 0;
        list-style: none;
        text-align: $start;
    }

    .iti input, .iti input[type=text], .iti input[type=tel]{
        direction: ltr;
        padding-#{$start}: 93px;
    }

    .auth .form-control{
        text-align: $start;
      }
      .login .form-control {
        text-align: left
    }
      

   

    
}

@import './direction';

.auth .form-control{
    border-radius: 0.25rem 0px 0px 0.25rem !important;
    background-color: #f0ffff03 !important;
    color: #fff;
    box-shadow: 0px;
    
  }
  
  .auth .form-group, .form-check {
    width: 50%;
  }
  .phone .ng-select span {
    color: #aaaaaa;
  }
  
  
  .phone .ng-select.custom .ng-select-container {
    background-color: #ffeead00 !important;
    color: #c4c4c4 !important;
    border: 1px solid;
    border-right: 0px solid #c4c4c4;
    border-radius: 5px 0px 0px 5px;
  }
  .phone .ng-select {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 25%);
  }
  
  .phone .form-control{
    border-radius: 0px 0px 0px 0px !important;
  }

  .phone .ng-dropdown-panel-items .ng-option {
    direction: ltr;
    text-align: center;
}

.phone-group {
    display: flex;
    flex-direction: column;
}

.auth .iti {
    width: calc(100% - 45px);
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 37px;
    height: 37px;
    padding: 8px;
}
  

@media (max-width:767px) {
    .form-check{
        margin-bottom: 17px;
        padding: 15px 0px;
    }

}
