@import "../colors";
@mixin biDirectional($start, $end) {
    .logo-details img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 5px;
    }
    .sidebar {
        position: fixed;
        #{$start}: 0;
        top: 0;
        height: 100%;
        width: 78px;
        background: $active-space-grey;
        padding: 6px 14px;
        padding-#{$start}: 17px;
        margin-top: 60px;
        z-index: 99;
        transition: all 0.5s ease;
        box-shadow: $shadow;
    }
    .sidebar.open {
        width: 250px;
        padding: 0px !important;
    }
    .sidebar .logo-details {
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        margin-#{$end}: 2px;
    }
    .sidebar .logo-details .icon {
        opacity: 0;
        transition: all 0.5s ease;
    }
    .sidebar .logo-details .logo_name {
        color: $primary-bg;
        font-size: 20px;
        font-weight: 600;
        opacity: 0;
        transition: all 0.5s ease;
    }
    .sidebar.open .logo-details .icon,
    .sidebar.open .logo-details .logo_name {
        opacity: 1;
    }
    .sidebar .logo-details #btn {
        position: absolute;
        top: 50%;
        #{$end}: 0;
        transform: translateY(-50%);
        font-size: 22px;
        transition: all 0.4s ease;
        font-size: 23px;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s ease;
    }
    .sidebar.open .logo-details #btn {
        text-align: #{$end};
    }
    .sidebar i {
        color: $primary-bg;
        height: 45px;
        min-width: 45px;
        font-size: 28px;
        text-align: center;
        line-height: 45px;
    }
    .sidebar .nav-list {
        margin-top: 20px;
        height: calc(100% - 140px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .sidebar li {
        position: relative;
        margin: 8px 0;
        list-style: none;
        border-radius: 5px;
        border: 0px solid $space-grey;
        height: 45px;
        overflow: hidden;
        transition: width 0.4s ease;
    }
    .sidebar li a {
        display: flex;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        align-items: center;
        text-decoration: none;
        transition: all 0.2s;
    }
    .sidebar li a:hover {
        background: $hover-space-grey;
    }
    .sidebar li a .links_name {
        color: $primary-bg;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
        opacity: 0;
        pointer-events: none;
        transition: 0.4s ease;
    }
    .open img{
        display: block !important;
    }
    .sidebar.open li a .links_name {
        opacity: 1;
        pointer-events: auto;
    }
    .sidebar li a:hover .links_name,
    .sidebar li a:hover i {
        transition: all 0.5s ease;
        color: #fff;
    }
    .sidebar li i {
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        border-radius: 5px;
    }
    .main-section {
        position: relative;
        min-height: 100vh;
        padding: 1rem;
        padding-top: calc(60px + 1rem);
        #{$start}: 78px;
        width: calc(100% - 78px);
        transition: all 0.5s ease;
        z-index: 2;
    }
    .sidebar.open~.main-section {
        #{$start}: 250px;
        width: calc(100% - 250px);
    }
    .main-section .text {
        display: inline-block;
        color: $space-grey;
        font-size: 25px;
        font-weight: 500;
        margin: 18px
    }
    .item a {
        border-radius: 0px !important;
        padding: 5px 15px;
    }
    .item li {
        margin: 4px 8px;
    }
    .sidebar.close li {
        height: 45px;
        padding-bottom: 0px;
    }
    .sidebar.close .active {
        border-#{$start}: 3px solid $warning;
    }
    .sidebar .active {
        background: $hover-space-grey;
        color: $space-grey;
    }
    .sidebar .show {
        height: auto;
        border: 0px;
        background: $space-space-grey;
        padding-bottom: 7px;
    }
    .link {
        border: 0px;
    }
    .sidebar.open .active .link {
        border-#{$start}: 5px solid $warning;
        border-radius: 0;
        color: $space-grey;
    }
    .sidebar .active2 {
        background-color: $active-space-grey;
        border-radius: 5px;
        color: $space-grey;
    }

    .active2 .links_name  {
        color: $space-grey !important;
    }
    // .active .link .links_name{
    //     color: $space-grey
    // }
    .sidebar span {
        margin-#{$end}: auto;

    }
    .arrow-close {
        margin: 0px !important
    }
    .arrow-open {
        margin: 0px !important;
        display: none;
    }
    .show .arrow-close {
        display: none;
    }
    .show .arrow-open {
        display: block;

    }
    @media (max-width: 1000px) {
        .sidebar.open~.main-section {
            #{$start}: 78px;
            width: calc(100% - 78px);
        }
    }
}

@import '../direction';
