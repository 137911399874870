::ng-deep body {
    &[dir="ltr"] {
        @include biDirectional(left, right);
    }

    &[dir="rtl"] {
        @include biDirectional(right, left);
    }
}

body {
    &[dir="ltr"] {
        @include biDirectional(left, right);
        .modal-body  ,.modal-content{
            direction: ltr;
        }
        .modal-footer{
            direction: rtl !important;
        }
        table{
            direction: ltr;
        }
        .form-check .form-check-input {
            float: none;
            margin-left: 12px !important;
        }

        .iti input, .iti input[type=text], .iti input[type=tel]{
            direction: ltr;
            padding-left: 93px;
            padding-right: 0px;
        }

    }
    

    &[dir="rtl"] {
        @include biDirectional(right, left);
        .modal-body ,.modal-content{
            direction: rtl;
        }
        .modal-footer{
            direction: ltr !important;
        }
        table{
            direction: rtl;
        }
        .form-check-input[type=checkbox] {
            margin-right: 12px;
            margin-left: 2px;
        }

        .iti__flag-container{
            left: 0 !important;
            right: auto !important;
        }
        
       
    }
}