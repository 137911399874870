.details-container {
  border-radius: 10px;
  min-height: 80px;
  background: $primary-bg;
  margin: 20px 0px;
  padding-bottom: 0.25rem;
  .tabel-content {
    font-size: 16px;
    font-weight: bold;
    color: #818181;
  }
}
.tabel-detais {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.details-header {
  border-bottom: 1px solid rgba(209, 213, 219, 1);
}
.tabel-content {
  font-size: 16px;
  font-weight: bold;
  color: $text;
}

.massage-stat-denger {
  background-color: red !important;
  color: white !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 9px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.massage-stat-success {
  background-color: #ffcd12 !important;
  color: white !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 9px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.head-card-gray {
  background-color: #f3f3f3;
}

.box-inside-box {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.stepper-container {
  padding: 0 80px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-point {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  color: #fff;
  background: #656565;
  font-size: 14px;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  border-color: 10px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .stepper-title {
    position: absolute;
    top: 40px;
    font-weight: 400;
    width: 200px;
    left: -82px;
  }
  .stepper-checked,
  &.past .stepper-number {
    display: none;
  }

  &.past .stepper-checked {
    display: inline-block;
  }
}

.stepper-active {
  background-color: #ffcd12 !important;
}

.stepper-active-error {
  background-color: red !important;
}

.stepper-line {
  height: 3px;
  width: 100%;
  background-color: #f2f5ff;
}
