@mixin biDirectional($start, $end) {
    .card-1 {
        border-bottom: 1px solid #E5E5E5;
    }
    .card-2 {
        border-bottom: 1px solid #E5E5E5;
        border-#{$start}: 1px solid #E5E5E5;
    }
    .card-4 {
        border-#{$start}: 1px solid #E5E5E5;
    }

    
}

@import '../direction';