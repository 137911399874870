@import "colors";

/* width */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

::-webkit-scrollbar-track {
    background: $active-space-grey;
}

td
/* Track */

::-webkit-scrollbar-track,
.page ::-webkit-scrollbar-track {
    background: $secondary-bg;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: $hover-space-grey;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: $hover-space-grey;
}
